import { LoginPageText } from "../../constants";
import LoginPageLogo from "../../assets/images/navigator-logo.svg";
import LoginPageVideo from "../../assets/images/login-info-clip.mp4";

function Login() {
  const handleSSOSignIn = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/azure/azure-ad`;
  };

  localStorage.clear();

  return (
    <section className="page-wrapper hp-bg-sp d-flex">
      <div className="login-page-info">
        <div className="login-page-logo-sec">
        <div className="navigator-ai-logo">
          <img
            src={LoginPageLogo}
            alt="navigatorai-img"
            className="img-fluid"
          />
        </div>
        <p class="wc-data-text"><span>“InizioNavigator.ai”</span> {LoginPageText}</p>
        <button
          className="google-sign-in-btn position-relative"
          onClick={handleSSOSignIn}
        >
          Sign in with SSO
        </button>
        </div>
        
      </div>
      <div className="login-page-video-sec">
      <video className='videoTag' autoPlay loop muted>
        <source src={LoginPageVideo} type='video/mp4' />
      </video>
      </div>
    </section>
  );
}

export default Login;
