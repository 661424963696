import "./styles/global.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import LandingPage from "./pages/LandingPage";
import Auth from "./pages/Auth";
import PrivateRoute from "./components/Common/ProtectedRoute";
import Projects from "./pages/Projects";
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { Fragment } from "react";
import { Toaster } from "./components/uikit/src/components/toast";

LicenseInfo.setLicenseKey(
  '0340e39ded9da060fefc7bf3f9bac8edTz03MTg0MixFPTE3MjI0OTY0MzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

function App() {
  return (
    <Fragment>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <LandingPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <Projects />
              </PrivateRoute>
            }
          />
          <Route path="/auth" element={<Auth />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
