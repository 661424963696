import React from "react";

import "./index.css";
import { Link } from "react-router-dom";
import { Link as ScrollLink, Element as ScrollElement } from "react-scroll";

export default function SsoHeader({
  logo,
  username,
  useremail,
  iconsList,
  UserIcons,
  logoutIcon,
  logoutCallback,
}) {
  

  return (
    <div className="header">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <img src={logo} alt="" className={"company_logo"} />
        </div>
        <div className="menu-item">
          <ScrollLink
            activeClass="active"
            to="newUpdates"
            spy={true}
            smooth={true}
            offset={-30}
            duration={400}
          >
            <p>New updates</p>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="ourSolutions"
            spy={true}
            smooth={true}
            offset={-30}
            duration={400}
          >
            <p>Our solutions</p>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="contactUs"
            spy={true}
            smooth={true}
            offset={-30}
            duration={400}
          >
            <p>Contact Us</p>
          </ScrollLink>
          <ScrollLink
            activeClass="active"
            to="userGuide"
            spy={true}
            smooth={true}
            offset={-30}
            duration={400}
          >
            <p>User Guide</p>
          </ScrollLink>
        </div>
      </div>
      <div className="head-right-icons">
        {/* {iconsList.map((icons) => (
          <img className="icon" src={icons} alt="" />
        ))} */}
        <div className="person-details">
          <img src={UserIcons} alt="" />
          <div className="person-text-detail">
            <p>{username}</p>
            <p>{useremail}</p>
          </div>
        </div>
        <img
          className="icon cursor-pointer"
          src={logoutIcon}
          alt=""
          onClick={() => logoutCallback()}
        />
      </div>
    </div>
  );
}
