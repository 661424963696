import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import './style.css';

export default function Table({ columns, rows, getRowId, pinnedColumns, onRowClick, onRowDoubleClick, onRowsScrollEnd }) {
    const theme = createTheme({
        typography: {
            fontFamily: 'inherit'
        }
    });

    const style = {
        '&.MuiDataGrid-root': {
            height: '492px !important',
            border: 'none',
            borderRadius: '4px',
            '& .MuiDataGrid-row': {
                '&:hover': {
                    cursor: 'pointer'
                },
                '&.Mui-selected': {
                    backgroundColor: '#E2E1FF',
                    border: '1px solid #4F46E5 !important'
                },
                borderColor: '#F1F5F9 !important'
            },
            '& .MuiDataGrid-cell': {
                cursor: 'pointer',
                '&:focus-within': {
                    outline: 'none !important'
                }
            },
            '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#E8E8E8'
            },
            '& .MuiDataGrid-columnHeader': {
                padding: '0px',
                backgroundColor: '#E8E8E8',
                '&TitleContainer': {
                    padding: '0px 12px'
                },
                '&:focus-within': {
                    outline: 'none !important'
                }
            },
            '& .MuiDataGrid-virtualScrollerRenderZone': {
                overflowY: "scroll"
            }
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div className='w-full h-full'>
                <DataGridPro
                    sx={style}
                    columnHeaderHeight={48}
                    initialState={{ pinnedColumns }}
                    onRowClick={onRowClick}
                    onRowDoubleClick={onRowDoubleClick}
                    rowHeight={64}
                    columns={columns}
                    getRowId={getRowId}
                    disableColumnMenu
                    hideFooter
                    rows={rows}
                    disableColumnResize
                    disableColumnReorder
                    onRowsScrollEnd={onRowsScrollEnd}
                />
            </div>
        </ThemeProvider>
    );
}