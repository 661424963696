import NorthEastIcon from "@mui/icons-material/NorthEast";

function NavCardLayout(props) {
  return (
    <>
      {props.cardProps.cardDetails.cardIsUpcoming ? (
        <>
          <div className="coming-soon-nav">
            <div className="d-flex align-items-center justify-content-center flex-column overlay">
              <div className="coming-soon-icon-sec">
                <img
                  src={props.cardProps.cardDetails.imageUrl}
                  alt="img-icon"
                />
              </div>
              <div className="navcard-icon-text-sp">
                <h4 className="mb-0">
                  <span className="overlay-text-ttl overlay-text-ttl-dark">
                    {props.cardProps.cardDetails.heading}
                  </span>
                  <span className="overlay-text-ttl overlay-text-ttl-blue">
                    {props.cardProps.cardDetails.subHeading}
                  </span>
                </h4>
                <p className="navcard-inizio-logo-text">InizioNavigator.ai</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="nav-card">
            <div className="overlay-section">
              <div className="overlay-right-top-arrow">
                <NorthEastIcon style={{ color: "#34B8A7" }} />
              </div>
              <div className="d-flex align-items-center flex-column">
                <div className="">
                  <h4 className="mb-0">
                    <span className="overlay-text-ttl overlay-text-ttl-dark">
                      {props.cardProps.cardDetails.heading}
                    </span>
                    <span className="overlay-text-ttl overlay-text-ttl-blue">
                      {props.cardProps.cardDetails.subHeading}
                    </span>
                  </h4>
                  <p className="navcard-inizio-logo-text">InizioNavigator.ai</p>
                </div>
                <p className="overlay-pra">
                  {props.cardProps.cardDetails.paragraph}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column overlay">
              <div className="navcard-center-icon-sec">
                <img
                  src={props.cardProps.cardDetails.imageUrl}
                  alt="img-icon"
                />
              </div>
              <div className="navcard-icon-text-sp ">
                <h4 className="mb-0">
                  <span className="overlay-text-ttl overlay-text-ttl-dark">
                    {props.cardProps.cardDetails.heading}
                  </span>
                  <span className="overlay-text-ttl overlay-text-ttl-blue">
                    {props.cardProps.cardDetails.subHeading}
                  </span>
                </h4>
                <p className="navcard-inizio-logo-text">InizioNavigator.ai</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NavCardLayout;
