import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import config from "../../services/config";
import { useCookies } from 'react-cookie';
import { ErrorToast, SuccessToast } from '../../components/uikit/src/components/toast';
import Loader from '../../components/uikit/src/components/Loader';

import _ from "lodash";

function EmailQueryLayout() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)

    const [cookies, removeCookie] = useCookies(["inizionavigator"]);
    const UserEmail = cookies.inizionavigator && cookies.inizionavigator.userLoginEmail;

    // Define state to get email content for the input TextArea
    const [userEmailContent, setUserEmailContent] = useState("");

    const handleEmailContent = () => {
        // Call email function for RUN API's
        fetchEmailFunc(userEmailContent);
    }

    // Email Function
    const fetchEmailFunc = async (emailContent) => {
        try {
            const body = {
                "content": emailContent
            }
            setIsLoading(true);
            const data = await config.post(`api/mail`, body, {
                headers: {
                    Authorization: `Bearer ${cookies?.inizionavigator?.token}`
                }
            })
            if (data.status === 201) {
                setIsLoading(false);
                SuccessToast('Your Email send successfully!');
                setUserEmailContent("");
            }
        } catch (error) {
            ErrorToast(error?.message || 'Something went wrong!');
            if (error?.response?.status === 401) {
                HandleLogout();
            }
        }
    };
    
    // #END Email Funtion

    // Handle Logout
    const HandleLogout = async () => {
        await AuthService.Logout(UserEmail)
        removeCookie(`inizionavigator`, {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        navigate("/login");
    };

    return (
        <>
        {isLoading && <Loader />}
        <div className="email-box">
            <textarea
                placeholder="Type your message here.... "
                className="text-area-sp"
                value={userEmailContent}
                onChange={(e) => setUserEmailContent(e.target.value)}
            ></textarea>
            <div className="send-email-btn" onClick={handleEmailContent}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                >
                    <path
                        d="M19.25 1.75L9.625 11.375"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M19.25 1.75L13.125 19.25L9.625 11.375L1.75 7.875L19.25 1.75Z"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <p>Send Message</p>
            </div>
        </div>
        </>
        
    );
}

export default EmailQueryLayout;
