import axios from "axios";
import JSCookie from "js-cookie";
import { reject } from "lodash";

const FetchToken = () => {
  if (!JSCookie.get("inizionavigator")) return null;
  const token = JSON.parse(JSCookie.get("inizionavigator")).token;
  return token;
};
const Acctoken = FetchToken();

const config = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

config.interceptors.request.use(
  function (response) {
    // If the response is successful, return it as-is
    return response;
  },
  function (error) {
    // If a 401 error is encountered, redirect to the login page
    if (error.response.status === 401) {
      window.location.replace('login') // Redirect to the login page
    }
    // Return any other errors without modification
    return Promise.reject(error);
  }
)

config.interceptors.response.use(
  function (response) {
    // If the response is successful, return it as-is
    return response;
  },
  function (error) {
    // If a 401 error is encountered, redirect to the login page
    if (error.response.status === 401) {
      window.location.replace('login') // Redirect to the login page
    }
    // Return any other errors without modification
    return Promise.reject(error);
  }
)

const configV2 = axios.create({
  baseURL: process.env.REACT_APP_IPT_BASE_URL,
  // headers: {
  //     Authorization: `Bearer ${Acctoken}`,
  // }
});

configV2.interceptors.request.use(
  function (response) {
    // If the response is successful, return it as-is
    return response;
  },
  function (error) {
    // If a 401 error is encountered, redirect to the login page
    if (error.response.status === 401) {
      window.location.replace('login') // Redirect to the login page
    }
    // Return any other errors without modification
    return Promise.reject(error);
  }
)

configV2.interceptors.response.use(
  function (response) {
    // If the response is successful, return it as-is
    return response;
  },
  function (error) {
    // If a 401 error is encountered, redirect to the login page
    if (error.response.status === 401) {
      window.location.replace('login') // Redirect to the login page
    }
    // Return any other errors without modification
    return Promise.reject(error);
  }
)

export { configV2 };

export default config;