import config from "./config";

const Logout = async (email) => {
  try {
    const response = await config.post("/azure/logout", { email });
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

const IsAdmin = async (email, token) => {
  try {
    const response = await config.post("/api/is-admin", { search: email }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
}

export const FetchUsers = async (searchQuery, token) => {
  const { data } = await config.post("/api/users", { search: searchQuery }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data;
}

export  const tokenValidationApi = async (payload) => {
  const  data  = await config.post("/azure/validate-token",payload)
  return data;
};

export const refreshTokenApi = async (payload, token) => {
  try {
    const response = await config.post('/azure/refresh-token', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    debugger;
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const LogoutApi = async (token) => {
  try {
    const response = await config.post(`/azure/logout`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export default {
  Logout,
  IsAdmin
};
