import React from 'react';
import { toast, Toaster as NewToaster } from 'react-hot-toast';
import './style.css';
import CloseIcon from '@mui/icons-material/Close';
import SuccessIcon from '../assets/images/successIcon.svg';
import AlertIcon from '../assets/images/alert-triangle.svg';

export const Toaster = () => <NewToaster position='top-right' />

export const SuccessToast = (successMessage) => {
    return toast(
        (t) => (
            <div className="toaster-css-for-success">
                <div className="toaster-success">
                    <div className="flex-propertie-wi-jc">
                        <img
                            src={SuccessIcon}
                            alt=""
                            className="error-tri"
                        />
                        Success{' '}
                    </div>
                    <CloseIcon
                        className="error-toat-close-icon"
                        onClick={() => toast.remove(t.id)}
                    />{' '}
                </div>
                <div className="toaster-success-msg">{successMessage}</div>
            </div>
        ),
        { className: 'main-toaster-class' },
    );
};

export const ErrorToast = (errorMessage) => {
    return toast(
        (t) => (
            <div className="toaster-css">
                <div className="toaster-error">
                    <div className="flex-propertie-wi-jc">
                        <img
                            src={AlertIcon}
                            alt=""
                            className="error-tri"
                        />
                        Error
                    </div>
                    <CloseIcon
                        className="error-toat-close-icon"
                        onClick={() => toast.remove(t.id)}
                    />
                </div>
                <div className="toaster-error-msg">{errorMessage}</div>
            </div>
        ),
        { className: 'main-toaster-class' },
    );
};