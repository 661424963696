import React from 'react';

import './index.css';

export default function Header({
  logo,
  username,
  useremail,
  iconsList,
  UserIcons,
  logoutIcon,
  logoutCallback,
}) {
  return (
    <div className="header">
      <div className="header-company-icon">
        <img
          src={logo}
          alt=""
          className={'company_logo'}
        />
      </div>
      <div className="head-right-icons">
        {iconsList.map((icons) => (
          <img
            className="icon"
            src={icons}
            alt=""
          />
        ))}

        <div className="person-details">
          <img
            src={UserIcons}
            alt=""
          />
          <div className="person-text-detail">
            <p>{username}</p>
            <p>{useremail}</p>
          </div>
        </div>
        <img
          className="icon"
          src={logoutIcon}
          alt=""
          onClick={() => logoutCallback()}
        />
      </div>
    </div>
  );
}
