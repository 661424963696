import React from 'react'
import MuiTooltip from "@mui/material/Tooltip";
import { createTheme } from '@mui/material';

export default function Tooltip(props) {

    const defaultStyle = { tooltip: { sx: { backgroundColor: '#F8F7F7', color: '#25282B', border: "1px solid #CDCDCD", borderRadius: '4px' }, } }

    return (
        <MuiTooltip componentsProps={defaultStyle} {...props} />
    )
}
