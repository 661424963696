import { Route, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const isAuthenticated = (cookies) => {
  const token = cookies.inizionavigator ? cookies.inizionavigator.token : null;
  return token !== null && token !== undefined;
};

const PrivateRoute = ({ children }) => {
  const [cookies] = useCookies(["inizionavigator"]);

  return isAuthenticated(cookies) ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
