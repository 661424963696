import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import AuthService, { LogoutApi, refreshTokenApi, tokenValidationApi } from "../../services/AuthService";

export default function Auth() {
  const [tokenParams, setTokenParams] = useSearchParams();

  const [cookies, setCookie, removeCookie] = useCookies(['inizionavigator']);

  const UserName =
    cookies.inizionavigator && cookies.inizionavigator.userLoginName;
  const UserEmail =
    cookies.inizionavigator && cookies.inizionavigator.userLoginEmail;
  const token = cookies.inizionavigator && cookies.inizionavigator.token;

  let experieTimeStamp = cookies.inizionavigator && cookies.inizionavigator.exp
  const navigate = useNavigate();
  useEffect(() => {
    async function validateToken(token) {
      try {
        const payload = {
          token: token,
        };
        const response = await tokenValidationApi(payload);
        if (response?.data?.is_success == true) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }

    async function HandleSSORedirection() {
      if (cookies.inizionavigator && cookies.inizionavigator.token) {
        navigate("/");
      } else {
        const isAdmin = await AuthService.IsAdmin(
          cookies.inizionavigator && cookies.inizionavigator.email,
          cookies.inizionavigator && cookies.inizionavigator.token
        );

        let tokenValidation = await validateToken(
          cookies.inizionavigator && cookies.inizionavigator.token
        );

        if (!tokenValidation) {
          return navigate("/login");
        }

        if (isAdmin) {
          return navigate("/admin", {
            state: {
              isAdmin: true,
            },
          });
        }

        navigate("/");
      }
    }
    HandleSSORedirection();
  }, []);

  useEffect(() => {
    if (cookies?.inizionavigator?.refreshToken) {
      const tokenExpiry = cookies?.inizionavigator?.exp;
      const currentTime = Date.now();


      const timeDifference = tokenExpiry * 1000 - currentTime - 3 * 60 * 1000;

      setTimeout(() => {
        (async () => {
          try {
            const refreshToken = cookies?.inizionavigator?.refreshToken;
            const payload = {
              refresh_token: refreshToken,
            };

            const response = await refreshTokenApi(payload, token);
            if (response?.is_success === true) {
              setCookie(`inizionavigator`, response?.responseCode, {
                path: '/',
                domain: process.env.REACT_APP_COOKIE_DOMAIN,
              });
            } else {
              await LogoutApi(token);
              navigate("/");
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            await LogoutApi(token);
            navigate("/");
          }
        })()
      }, timeDifference);
    }
  }, [cookies?.inizionavigator, token, setCookie, removeCookie]);
  return <></>;
}
