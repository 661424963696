import React, { Fragment } from 'react'
import { createPortal } from 'react-dom'
import { Rings } from 'react-loader-spinner'
import "./style.css";

export default function Loader() {
    return (
        <Fragment>
            {createPortal(
                <div className='w-screen z-10000 fixed top-0 left-0 h-screen flex items-center justify-center bg-white/80'>
                    <Rings
                        visible={true}
                        height="80"
                        width="80"
                        color="#4f46e595"
                        ariaLabel="rings-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                , document.body)}
        </Fragment>
    )
}
