// import CardIcon from "../assets/images/cardIcon.svg";
// import CardHoverIcon from "../assets/images/cardhover.svg";
// import ExploreonHoverImage from "../assets/images/arrowiconhover.svg";
// import ExploreonNonHoverImage from "../assets/images/arrowicon.svg";

import AssetNavCardIcon from "../assets/images/AssetNavCardIcon.svg";
import IndicationsCardIcon from "../assets/images/IndicationsCardIcon.svg";
import QuantNAVCardIcon from "../assets/images/QuantNAVCardIcon.svg";
import VocInsightsCardIcon from "../assets/images/VocInsightsCardIcon.svg";
import ComingSoonCardIcon from "../assets/images/coming_soon.png";

export const LoginPageText = 'specializes in AI-driven solutions for the life sciences sector, offering an advanced platform that analyzes complex medical data. Their focus is on providing actionable insights to medical affairs, regulatory affairs, and safety teams, ultimately aiming to improve patient care and outcomes.';
export const LandingPageBannerText = "Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.";
export const LandingPageHead = "About Us";
export const CardContainerHead = "Our Solutions";
export const CardContainerDesc = "Vorem ipsum dolor sit amet, consectetur adipiscing elit. ";
export const AssetNavCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: false,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "Asset",
    subHeading: "NAV",
    paragraph: "An asset prioritization tool that supports with BD scans for one or more therapeutic areas and powers strategic recommendations.",
    imageUrl: AssetNavCardIcon,
    // imageUrlOnHover: CardHoverIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const IndicationsCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: false,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "Indication",
    subHeading: "NAV",
    paragraph: "An innovative tool for indication prioritization, features a quick search function, state-of-the-art scoring module, and relevant visualizations, ensuring efficient and precise execution of indication prioritization.",
    imageUrl: IndicationsCardIcon,
    // imageUrlOnHover: CardHoverIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const VocInsightsCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: false,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "Clarity",
    subHeading: "Nav",
    paragraph: "In the pharmaceutical landscape, a cutting-edge app utilizes Generative AI to automate HCP perception analysis from Voice of Customer data, enabling enterprises to navigate dynamic HCP sentiments for strategic decisions.",
    imageUrl: VocInsightsCardIcon,
    //imageUrl: ComingSoonCardIcon,
    // imageUrlOnHover: CardHoverIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const QuantNAVCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: false,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "Quant",
    subHeading: "NAV",
    paragraph: "A Quantitative project execution tool that enables user to perform data manipulations, apply powerful AI/ML algorithms and generate client ready visual strategic insights from the raw data.",
    imageUrl: QuantNAVCardIcon,
    // imageUrlOnHover: CardHoverIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const AssetNavV2CardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: false,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "Asset",
    subHeading: "NAV V2",
    paragraph: "An asset prioritization tool that supports with BD scans for one or more therapeutic areas and powers strategic recommendations.",
    imageUrl: AssetNavCardIcon,
    // imageUrlOnHover: CardHoverIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const TrialNAVIndicationsCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: true,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "TrialNAV",
    paragraph:
      "Lorem ipsum dolor sit amet consectetur. A tellus leo nisl vulputate ipsum sit.",
    imageUrl: QuantNAVCardIcon,
    // imageUrlOnHover: CardHoverIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const AnalogNAVCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: true,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "AnalogNAV",
    paragraph:
      "Lorem ipsum dolor sit amet consectetur. A tellus leo nisl vulputate ipsum sit.",
    imageUrl: QuantNAVCardIcon,
    // imageUrlOnHover: CardHoverIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const CenterNAVCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: true,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "CenterNAV",
    paragraph:
      "Lorem ipsum dolor sit amet consectetur. A tellus leo nisl vulputate ipsum sit.",
    imageUrl: QuantNAVCardIcon,
    // imageUrlOnHover: CardHoverIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const HtaNAVCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: true,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "HtaNAV",
    paragraph:
      "Lorem ipsum dolor sit amet consectetur. A tellus leo nisl vulputate ipsum sit.",
    imageUrl: QuantNAVCardIcon,
    // imageUrlOnHover: CardHoverIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const SurveyNAVCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: true,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "SurveyNAV",
    paragraph:
      "Lorem ipsum dolor sit amet consectetur. A tellus leo nisl vulputate ipsum sit.",
    imageUrl: QuantNAVCardIcon,
    // imageUrlOnHover: CardHoverIcon,
    imageHeight: "20px",
    mainDivClass: "card items-center",
    imageDivClass: "",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {
      fontSize: 12,
    },
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};
export const PatientNAVCardDetail = {
  cardType: "Square",
  cardDetails: {
    cardIsUpcoming: true,
    cardHeight: "260px",
    cardWidth: "206px",
    margin: "1rem",
    background: "#fff",
    backgroundOnHover: "linear-gradient(90deg, #4F46E5 0%, #B4B5F8 100%)",
    heading: "PatientNAV",
    paragraph:
      "Lorem ipsum dolor sit amet consectetur. A tellus leo nisl vulputate ipsum sit.",
    imageUrl: QuantNAVCardIcon,
    // imageUrlOnHover: CardHoverIcon,
    imageHeight: 20,
    mainDivClass: "card items-center",
    imageDivClass: "landing-card-image",
    imageClass: "",
    paragraphClass: "anlys-box-p",
    mainDivstyle: {},
    imageDivstyle: {},
    imagestyle: {},
    paragraphstyle: {},
    HeadingColorOnHover: "#FFFFFF",
    HeadingColor: "#272833",
    ParagraphColor: "#272833",
    ParagraphColorOnHover: "#FFFFFF",
    fontHeading: "medium",
    fontParagraph: "small",
    fontWeightParagraph: "regular",
    fontWeightHeading: "medium",
    expandContent: "Know more",
    spaceBetweenContent: "20px",
    PaddingBottomOnHead: "20px",
    cardHeadingClass: "cardHeadingClass",
    // ExpendingIcon: ExploreonNonHoverImage,
    // ExpendingIconOnHover: ExploreonHoverImage,
    ExpendingContentColor: "#4F46E5",
    ExpendingContentColorOnHover: "#FFFFFF",
  },
};

export const CardsListing = [
  {
    props: AssetNavCardDetail,
    index: 1,
    navigationPath: `${process.env.REACT_APP_ASSETNAV_URL}/score-search-selection`,
  },
  // apt v1 card
  // {
  //   props: AssetNavCardDetail,
  //   index: 1,
  //   navigationPath: `${process.env.REACT_APP_ASSETNAV_URL}/scope-analysis`,
  // },
  {
    props: IndicationsCardDetail,
    index: 2,
    navigationPath: `${process.env.REACT_APP_INDICATIONDEV_URL}/indications-selection`,
  },
  {
    props: VocInsightsCardDetail,
    index: 3,
    navigationPath: `${process.env.REACT_APP_VOCINSIGHTS_URL}/voc-input`,
    // navigationPath: "#",
  },
  {
    props: QuantNAVCardDetail,
    index: 4,
    navigationPath: `${process.env.REACT_APP_QUANT_URL}`,
  },
  // {
  //   props: AssetNavV2CardDetail,
  //   index: 5,
  //   navigationPath: `${process.env.REACT_APP_ASSETNAV_V2_URL}/score-search-selection`,
  // },
  // {
  //   props: TrialNAVIndicationsCardDetail,
  //   index: 1,
  //   navigationPath: "#",
  // },
  // {
  //   props: AnalogNAVCardDetail,
  //   index: 2,
  //   navigationPath: "#",
  // },
  // {
  //   props: CenterNAVCardDetail,
  //   index: 1,
  //   navigationPath: "#",
  // },
  // {
  //   props: HtaNAVCardDetail,
  //   index: 2,
  //   navigationPath: "#",
  // },
  // {
  //   props: SurveyNAVCardDetail,
  //   index: 2,
  //   navigationPath: "#",
  // },
  // {
  //   props: PatientNAVCardDetail,
  //   index: 2,
  //   navigationPath: "#",
  // },
];
