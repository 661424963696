import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import Header from '../../components/uikit/src/components/Header';
import CompanyLogo from "../../assets/images/navigator-logo.png";
import HomeIcon from "../../assets/images/home_icon.svg";
import QuestionMarkIcon from "../../assets/images/question-octagon.svg";
import NotificationIcon from "../../assets/images/notification-outline-badged.svg";
import LogoutIcon from "../../assets/images/Logout.svg";
import UserIcon from "../../assets/images/User.svg";
import { useCookies } from 'react-cookie';
import AuthService, { FetchUsers } from '../../services/AuthService';
import './style.css';
import ProjectTable from './ProjectTable';
import { format } from 'date-fns';
import { configV2 } from '../../services/config';
import Modal from '@mui/material/Modal';
import AsyncSelect from 'react-select/async';
import { ErrorToast, SuccessToast } from '../../components/uikit/src/components/toast';
import Tooltip from '../../components/uikit/src/components/tooltip';
import _ from 'lodash';
import { Rings } from 'react-loader-spinner';
import { createPortal } from 'react-dom';
import Loader from '../../components/uikit/src/components/Loader';

export default function Projects() {
    const [projectData, setProjectData] = useState([]);
    const [projectCount, setProjectCount] = useState(0);

    const [assignBtnLoading, setAssignBtnLoading] = useState(false)

    const [pageNo, setPageNo] = useState(1);

    const [isModelOpen, setIsModelOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const [isRestoreModelOpen, setIsRestoreModelOpen] = useState(false);
    const [restoreBtnLoading, setRestoreBtnLoading] = useState(false);
    const [restoreProjectId, setRestoreProjectId] = useState(null)

    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [modelError, setModelError] = useState("")
    const navigate = useNavigate();

    const [cookies, setCookie, removeCookie] = useCookies(["inizionavigator"]);
    const UserName =
        cookies.inizionavigator && cookies.inizionavigator.userLoginName;
    const UserEmail =
        cookies.inizionavigator && cookies.inizionavigator.userLoginEmail;
    const isAdmin =
        cookies.inizionavigator && cookies.inizionavigator.isAdmin;

    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                setIsLoading(true)
                const { data: projects } = await configV2.post(`/project/admin/global-search?limit=100&offset=${pageNo - 1}`,
                    {
                        globalSearchTerm: searchQuery
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${cookies?.inizionavigator?.token}`
                        },
                    }
                )
                setProjectData(projects.data?.items)
                setProjectCount(projects.data?.total);
            } catch (error) {
                if (error?.response?.status === 401) {
                    HandleLogout();
                }
            } finally {
                setIsLoading(false)
            }
        }
        fetchProjects();
    }, [searchQuery]);

    const columns = useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Name',
                width: 120,
                renderCell: (param) => (<Tooltip title={param.value} placement="left-end">
                    <span className="highlight">{param.value}</span>
                </Tooltip>)
            },
            {
                field: 'description',
                headerName: 'Description',
                width: 200,
                renderCell: (param) => (<Tooltip title={param.value} placement="left-end">{param.value}</Tooltip>)
            },
            {
                field: 'client_name',
                headerName: 'Client',
                width: 100,
                renderCell: (param) => (<Tooltip title={param.value} placement="left-end">{param.value}</Tooltip>)
            },
            {
                field: 'owner',
                headerName: 'Owner',
                width: 120,
                renderCell: (param) => (<Tooltip title={param.value} placement="left-end">{param.value}</Tooltip>)
            },
            {
                field: 'collaborators',
                headerName: 'Assignee',
                width: 170,
                renderCell: (param) => {
                    if (!param.value || param?.value?.length === 0) {
                        return <span className="flex items-center justify-center w-80px">
                            NA
                        </span>
                    }

                    const tooltipTitle = param.value.join(', ')

                    const [first, ...rest] = param.value;
                    return (
                        <Tooltip title={tooltipTitle} placement="left-end">
                            <span className="flex items-center">
                                <span className="inline-block max-w-130px text-ellipsis overflow-hidden">{first}</span>
                                <span>
                                    {rest && rest.length ? `+${rest.length}` : ``}
                                </span>
                            </span>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'is_deleted',
                headerName: 'Status',
                width: 100,
                renderCell: (param) => {
                    return param.value ? <Tooltip title="Deleted" placement="left-end"><div className="border-danger rounded-full text-xs p-2 text-danger font-medium py-1px">Deleted</div></Tooltip> : <Tooltip title="Saved" placement="left-end"><div className="border-success rounded-full text-xs p-2 text-success font-medium py-1px">Saved</div></Tooltip>
                },
            },
            {
                field: 'created_at',
                headerName: 'Created on',
                width: 120,
                renderCell: (param) => (<Tooltip title={format(new Date(param.value), 'dd-MMM-yyyy')} placement="left-end">{format(new Date(param.value), 'dd-MMM-yyyy')}</Tooltip>)
            },
            {
                field: 'updated_at',
                headerName: 'Last Updated',
                width: 120,
                renderCell: (param) => (<Tooltip title={format(new Date(param.value), 'dd-MMM-yyyy')} placement="left-end">{format(new Date(param.value), 'dd-MMM-yyyy')}</Tooltip>)
            },
            {
                field: 'type',
                headerName: 'Project Type',
                width: 150,
                renderCell: (param) => (<Tooltip title={param.value} placement="left-end">{param.value}</Tooltip>)
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                getActions: params => [
                    <Tooltip title={params.row?.owner ? "Update Owner" : "Add Owner"} placement="left-end">
                        <div onClick={async (e) => {
                            e.stopPropagation();
                            setSelectedProject(params.row)
                            const user = { email: params.row?.created_by, name: params.row?.owner }
                            setSelectedUser({ label: `${user.name}(${user.email})`, value: { ...user } })
                            setIsModelOpen(true)
                        }} className="flex items-center justify-center h-30px w-30px p-2.5 bg-background rounded-full">
                            <img alt="Plus User" src="/user-plus.svg" />
                        </div>
                    </Tooltip>,
                    <Tooltip title="Restore" placement="left-start">
                        <div onClick={(e) => {
                            e.stopPropagation();
                            if (params.row.is_deleted === false) return;
                            setRestoreProjectId(params.row.project_id);
                            setIsRestoreModelOpen(true)
                        }} className="flex items-center justify-center h-30px w-30px p-2.5 bg-background-success rounded-full">
                            <img alt="Restore" src="/restore.svg" width={14} height={14} />
                        </div>
                    </Tooltip>

                ]
            }
        ],
        []
    );

    const HandleLogout = async () => {
        await AuthService.Logout(UserEmail)
        removeCookie(`inizionavigator`, {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        navigate("/login");
    };

    const promiseOptions = (inputValue) =>
        new Promise(async (resolve) => {
            const data = await FetchUsers(inputValue, cookies?.inizionavigator?.token);
            const collaborators = new Set(selectedProject?.collaborators);
            resolve(data.filter(user => user).map(user => ({ label: `${user.name}(${user.email})`, value: { ...user }, isDisabled: collaborators.has(user.email) })));
        });

    const handleAssigningProjectOwner = async () => {
        try {
            setAssignBtnLoading(true)
            if (!selectedUser) {
                setModelError("Please select assginee")
                throw new Error('Owner couldn\'t be empty');
            }
            const body = {
                owner: selectedUser.value.name,
                created_by: selectedUser.value.email
            }

            const data = await configV2.put(`/project/assign/${selectedProject.project_id}`, body, {
                headers: {
                    Authorization: `Bearer ${cookies?.inizionavigator?.token}`
                }
            })

            if (data.status === 200) {
                const { data: response } = await configV2.get('/project/all/?limit=1000&page=1', {
                    headers: {
                        Authorization: `Bearer ${cookies?.inizionavigator?.token}`
                    }
                })
                setProjectData(response?.data?.items?.data);
            }
            SuccessToast('Project assigned to owner!')
            setIsModelOpen(false)
        } catch (error) {
            ErrorToast(error?.message || 'Something went wrong!')
            if (error?.response?.status === 401) {
                HandleLogout();
            }
        } finally {
            setSelectedUser(null)
            setSelectedProject(null)
            setAssignBtnLoading(false)
        }
    }

    const handleRestoreProject = async () => {
        try {
            setRestoreBtnLoading(true)
            const data = await configV2.put(`/project/restore/${restoreProjectId}`, {}, {
                headers: {

                    Authorization: `Bearer ${cookies?.inizionavigator?.token}`
                }
            })

            if (data.status === 200) {
                const { data: response } = await configV2.get('/project/all/?limit=1000&page=1', {
                    headers: {
                        Authorization: `Bearer ${cookies?.inizionavigator?.token}`
                    }
                })
                setProjectData(response?.data?.items?.data);
            }
            SuccessToast('Project is restored successfully!')
            handleRestoreModelClose()
        } catch (error) {
            ErrorToast(error?.message || 'Something went wrong!')
            if (error?.response?.status === 401) {
                HandleLogout();
            }
        } finally {
            setRestoreBtnLoading(false)
        }
    }

    const handleLoadMoreProjects = async (params) => {
        if (params.visibleRowsCount < projectCount) {
            const { data: projects } = await configV2.post(`/project/admin/global-search?limit=100&offset=${pageNo * 100}`,
                {
                    globalSearchTerm: searchQuery
                },
                {
                    headers: {
                        Authorization: `Bearer ${cookies?.inizionavigator?.token}`
                    },
                }
            )
            setPageNo(prev => prev + 1)
            setProjectData(prev => !_.isEmpty(prev) ? [...prev, ...projects.data?.items] : [])
        };
    }

    const handleOwnerAssignModelClose = () => {
        setIsModelOpen(false)
        setSelectedUser(null)
        setModelError("");
    }

    const handleRestoreModelClose = () => {
        setRestoreProjectId(null)
        setIsRestoreModelOpen(false)
    }

    const handleSearch = _.debounce(async (search) => {
        setSearchQuery(search)
    }, 1500);

    if (!isAdmin) {
        return <Navigate to="/" />
    }

    return (
        <div className="w-full max-w-screen flex flex-col min-h-screen bg-background">
            {isLoading && <Loader />}
            <Modal
                open={isModelOpen}
                className='main-model'
                onClose={handleOwnerAssignModelClose}
            >
                <div className='custom-modal'>
                    <div className="modal-header">
                        {!_.isEmpty(selectedUser) ? "Update owner" : "Assign owner"}
                        <img className='cursor-pointer' onClick={handleOwnerAssignModelClose} alt="Close" src="/x.svg" />
                    </div>
                    <div className="modal-body">
                        <div className="modal-content">
                            <label>Username / Email-ID</label>
                            <div className="model-content__input">
                                <AsyncSelect styles={{
                                    valueContainer: (provided, { isFocused }) => ({
                                        ...provided,
                                        maxHeight: '60px',
                                        padding: '8px',
                                    }),
                                    menuList: base => ({ ...base, maxHeight: '265px', fontSize: '14px' }),
                                    control: (provided, { isFocused }) => ({
                                        ...provided,
                                        border: isFocused ? '1px solid #E5E5E5' : '1px solid #E5E5E5',
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        overflow: 'hidden'
                                    }),
                                    container: (provided, { isFocused }) => ({
                                        ...provided,
                                        width: '100%',
                                        border: isFocused ? '1px solid #E5E5E5' : '1px solid #E5E5E5',
                                        minHeight: '44px',
                                        maxHeight: '55px',
                                        borderRadius: '4px',
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: 'none',
                                    }),
                                }} cacheOptions={true} onChange={(value) => {
                                    setSelectedUser(value)
                                    setModelError("")

                                }} classNamePrefix="async-select" placeholder="dummyuser@inizio.health" value={selectedUser} isOptionSelected={(option, selectValue) => selectValue.some(
                                    (val) => val.value.email === option.value.email
                                )} defaultOptions loadOptions={promiseOptions} />
                            </div>
                            {modelError && <div className="model-content__message">
                                <img className="model-content__icon" alt="error" src="/alert-triangle.svg" />
                                <span className="model-content__error">{modelError}</span>
                            </div>}
                        </div>
                    </div>
                    <footer className="modal-footer">
                        <button onClick={handleOwnerAssignModelClose} className="btn btn-primary-outline">Cancel</button>
                        <button onClick={handleAssigningProjectOwner} disabled={assignBtnLoading} className="btn btn-primary">{!_.isEmpty(selectedUser) ? "Save changes" : "Assign owner"}</button>
                    </footer>
                </div>
            </Modal>

            <Modal
                open={isRestoreModelOpen}
                className='main-model'
                onClose={handleRestoreModelClose}
            >
                <div className='restore-modal'>
                    <img className='restore-close-model cursor-pointer' onClick={handleRestoreModelClose} alt="Close" src="/cross-circle.svg" />
                    <img src="/restore-3d.png" alt="restore" height={93} />
                    <h1 className="restore-modal-heading">
                        Sure you want to restore?
                    </h1>
                    <p className="restore-modal-description">
                        The project will be restored. User would be able to access it.
                    </p>
                    <footer className="restore-modal-footer">
                        <button onClick={handleRestoreProject} disabled={restoreBtnLoading} className={`restore-btn ${restoreBtnLoading ? 'restore-btn-disable' : ''}`}>Yes confirm</button>
                        <button onClick={handleRestoreModelClose} className="restore-btn restore-btn-outline">No, cancel</button>
                    </footer>
                </div>
            </Modal>
            <div>
                <Header
                    iconsList={[HomeIcon, QuestionMarkIcon, NotificationIcon]}
                    logoutIcon={LogoutIcon}
                    UserIcons={UserIcon}
                    logo={CompanyLogo}
                    useremail={UserEmail}
                    username={UserName}
                    logoutCallback={HandleLogout}
                />
            </div>
            <div className='flex flex-col gap-4 flex-1 h-full pt-22px pr-31px pl-29px pb-42px'>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col items-start">
                        <h2 className="font-semibold text-lg leading-5">
                            Saved projects <span className="text-primary">({projectCount})</span>
                        </h2>
                        <span className="text-sm text-text font-medium leading-5">
                            View all projects.
                        </span>
                    </div>
                    <div className="flex items-center h-10 w-full max-w-368px border bg-white gap-2 px-2.5 py-2.5 rounded">
                        <img src="/Shapes.svg" alt="search-icon" />
                        <input className="text-sm placeholder:text-gray border-none" type='text' name="search" placeholder="Search" onChange={(event) => handleSearch(event.target.value)} />
                    </div>
                </div>
                <div className="bg-white flex-1 rounded px-2.5 py-3 h-full">
                    <ProjectTable columns={columns} rows={_.isEmpty(projectData) ? [] : projectData} pinnedColumns={{ right: ['actions'] }} getRowId={row => row.project_id} onRowsScrollEnd={handleLoadMoreProjects} />
                </div>
            </div>
        </div>
    )
}
